<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备特征码">
          <el-input v-model="searchForm.equipmentCode" autocomplete="off" size="small" placeholder="请输入设备特征码" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="deviceList" stripe style="width: 100%" height="100%">
        <el-table-column prop="equipmentCode" label="设备特征码" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column prop="typeName" label="设备类型" show-overflow-tooltip align="center">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top">
              <el-button type="warning" @click="open('edit',scope.row)" size="mini" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" @click="deleteDevice(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="15" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 产品库弹窗 -->
    <el-dialog :title="optFlag?`添加产品`:`修改产品`" :visible.sync="dialog" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="deviceForm" ref="deviceForm" :rules="rules">
        <el-form-item label="设备类型" prop="typeId" :label-width="formLabelWidth">
          <el-select v-model="deviceForm.typeId" placeholder="请选择设备类型" clearable style="width:100%">
            <el-option v-for="item in typeList" :key="item.value" :label="item.typeName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备特征码" prop="equipmentCode" :label-width="formLabelWidth">
          <el-input v-model="deviceForm.equipmentCode" autocomplete="off" placeholder="请输入设备特征码" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="addDevice" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateDevice" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      deviceList: [],
      typeList: [],
      currentPage: 1,
      total: 0,
      searchForm: {},
      deviceForm: {
        typeId: '',
        equipmentCode: ''
      },
      deviceFormCopy: {},
      optFlag: true,
      dialog: false,
      formLabelWidth: '100px',
      rules: {
        typeId: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ],
        equipmentCode: [
          { required: true, message: '请输入设备特征码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getTypeList()
    this.deviceFormCopy = JSON.stringify(this.deviceForm)
  },
  methods: {
    // 初始化表格
    async initTable () {
      this.searchForm.pageNo = this.currentPage
      this.searchForm.pageSize = 15
      if (!this.searchForm.equipmentCode) {
        this.$set(this.searchForm, 'equipmentCode', null)
      }
      const { data: result } = await this.$axios.get('/equipmentLibrary/list', { params: this.searchForm })
      if (result.code === 200) {
        this.deviceList = result.data.result
        this.total = result.data.total
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.$nextTick(() => {
            this.$refs.deviceForm.clearValidate()
          })
          this.dialog = true
          break
        case 'edit':
          this.optFlag = false
          this.dialog = true
          this.deviceForm = JSON.parse(JSON.stringify(row))
          break
      }
    },
    // 添加
    async addDevice () {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/equipmentLibrary/add', this.deviceForm)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.dialog = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改
    async updateDevice () {
      this.$refs.deviceForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/equipmentLibrary/edit/${this.deviceForm.id}`, this.deviceForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.dialog = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async deleteDevice (id) {
      const confirm = await this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/equipmentLibrary/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 查找设备类型
    async getTypeList () {
      const { data: result } = await this.$axios.get('/equipment-type/list')
      if (result.code === 200) {
        this.typeList = result.data
      }
    },
    clear () {
      this.deviceForm = JSON.parse(this.deviceFormCopy)
      this.$refs.deviceForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
